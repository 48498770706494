import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../service/api-service.service';
import { noUndefined } from '@angular/compiler/src/util';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var window: any;
window.$ = window.jQuery = $;
declare var $: any;
declare var Swiper: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  getstar: any;
  reviewList: any;
  message: any;
  public addFrom: any;

  constructor(public api: ApiServiceService, public FormBuilder: FormBuilder,public toast: ToastrService) {
    this.addFrom = this.FormBuilder.group({
      Name: new FormControl('',[ Validators.required]),
      Message: new FormControl('',[Validators.maxLength(300)]),
    })
  }

  ngOnInit() {
    $("#message-contact").keyup(function(){
      $("#count").text($(this).val().length);
    });
    $(function () {


      $(document).on("scroll", function () {
        if
          ($(document).scrollTop() > 86) {
          $(".affix-top").addClass("affix");
        }
        else {
          $(".affix-top").removeClass("affix");
        }
      });
    });

    $(document).ready(function () {
      $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        autoHeight: false,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    });

    $(document).ready(function () {
      $('#media').carousel({
        pause: true,
        interval: false,
      });
    });

    // Starrr plugin (https://github.com/dobtco/starrr)
    var __slice = [].slice;
    var THIS = this;
    (function ($, window) {
      var Starrr;

      Starrr = (function () {
        Starrr.prototype.defaults = {
          rating: void 0,
          numStars: 5,
          change: function (e, value) { }
        };

        function Starrr($el, options) {
          var i, _, _ref,
            _this = this;

          this.options = $.extend({}, this.defaults, options);
          this.$el = $el;
          _ref = this.defaults;
          for (i in _ref) {
            _ = _ref[i];
            if (this.$el.data(i) != null) {
              this.options[i] = this.$el.data(i);
            }
          }
          this.createStars();
          this.syncRating();
          this.$el.on('mouseover.starrr', 'span', function (e) {
            return _this.syncRating(_this.$el.find('span').index(e.currentTarget) + 1);
          });
          this.$el.on('mouseout.starrr', function () {
            return _this.syncRating();
          });
          this.$el.on('click.starrr', 'span', function (e) {
            return _this.setRating(_this.$el.find('span').index(e.currentTarget) + 1);
          });
          this.$el.on('starrr:change', this.options.change);
        }

        Starrr.prototype.createStars = function () {
          var _i, _ref, _results;

          _results = [];
          for (_i = 1, _ref = this.options.numStars; 1 <= _ref ? _i <= _ref : _i >= _ref; 1 <= _ref ? _i++ : _i--) {
            _results.push(this.$el.append("<span class='glyphicon .glyphicon-star-empty'></span>"));
          }
          return _results;
        };

        Starrr.prototype.setRating = function (rating) {
          if (this.options.rating === rating) {
            rating = void 0;
          }
          this.options.rating = rating;
          this.syncRating();
          return this.$el.trigger('starrr:change', rating);
        };

        Starrr.prototype.syncRating = function (rating) {
          var i, _i, _j, _ref;

          rating || (rating = this.options.rating);
          if (rating) {
            for (i = _i = 0, _ref = rating - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
              this.$el.find('span').eq(i).removeClass('glyphicon-star-empty').addClass('glyphicon-star');
            }
          }
          if (rating && rating < 5) {
            for (i = _j = rating; rating <= 5 ? _j <= 5 : _j >= 5; i = rating <= 5 ? ++_j : --_j) {
              this.$el.find('span').eq(i).removeClass('glyphicon-star').addClass('glyphicon-star-empty');
            }
          }
          if (!rating) {
            return this.$el.find('span').removeClass('glyphicon-star').addClass('glyphicon-star-empty');
          }
        };

        return Starrr;

      })();
      return $.fn.extend({
        starrr: function () {
          var args, option;

          option = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
          return this.each(function () {
            var data;

            data = $(this).data('star-rating');
            if (!data) {
              $(this).data('star-rating', (data = new Starrr($(this), option)));
            }
            if (typeof option === 'string') {
              return data[option].apply(data, args);
            }
          });
        }
      });
    })(window.jQuery, window);

    $(function () {
      return $(".starrr").starrr();
    });

    $(document).ready(function () {

      $('#stars').on('starrr:change', function (e, value) {
        $('#count').html(value);
        THIS.getstar = value;
        console.log(THIS.getstar);



      });

      $('#stars-existing').on('starrr:change', function (e, value) {
        $('#count-existing').html(value);
      });
    });

    this.getallreview();




    new Swiper('.swiper-container', {
      // Swiper options here...
      loop: true,
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
  });

  }





  registerform() {
    // console.log("sid");
    var name = $("#name").val();
    var email = $("#email").val();
    var course = $("#courses").val();
    var no = $("#no").val();
    var add = $("#add").val();
    var data = { "Name": name, "Email": email, "Course": course, "PhoneNumbner": no, "Address": add };
    this.api.usersignUp(data).subscribe(res => {
      console.log(res);
      this.toast.success("Succesfully");
      setTimeout(function(){ window.location.reload(); }, 1000);
    }, err => {
      console.log(err.error);
    })
  }

  contactform() {
    // console.log("sid");
    var name1 = $("#name1").val();
    var email1 = $("#email1").val();
    var msg1 = $("#msg1").val();

    var data = { "Name": name1, "Email": email1, "Message": msg1 };
    this.api.usercontactUs(data).subscribe(res => {
      console.log(res);
      this.toast.success("Succesfully");
      setTimeout(function(){ window.location.reload(); }, 1000);
    }, err => {
      console.log(err.error);
    })
  }
  reviewform() {
    var name = $("#rname").val();
    var msg = $("#message-contact").val();
    var countget = $("#count").val();
    var star = this.getstar;
    console.log(this.getstar);

    var data = { "name": name, "review": msg, "star": star }
    console.log(data);
    if (name != "" && msg != "" && star != undefined) {
      // this.toastr.error('Please enter Required');
      this.api.userreview(data).subscribe(res => {
        console.log(res);
        this.toast.success("Succesfully");
        setTimeout(function(){ window.location.reload(); }, 1000);
      }, err => {
        console.log(err.error);
      });

    }
    else {
      alert("pls filled the form");
    }

  }

  getallreview() {
    return this.api.getreview().subscribe(res => {
      console.log(res);
      if (res) {
        this.reviewList = res['data'];
        $(document).ready(function () {
          var swiper = new Swiper('.swiper-container4', {
            slidesPerView: 1,
            spaceBetween: 10,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
            // init: false,
            /*  pagination: {
               el: '.swiper-pagination',
               clickable: true,
             }, */
            breakpoints: {
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }
          });
        });
      }
    });
  }
}



