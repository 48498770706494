<div id="wrapper" class="wrapper clearfix" style="font-family: 'Open Sans' !important;">
    <!-- Header
    ============================================= -->
    <header id="navbar-spy" class="header header-1 header-transparent header-fixed">

        <nav id="primary-menu" class="navbar navbar-fixed-top affix-top">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#navbar-collapse-1" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>

                    <a class="logo" href="index-2.html">
                        <img class="logo-dark" src="assets\images\logo\logo-light.jpg" alt="appy Logo">
                        <img class="logo-light" src="assets\images\logo\logo-dark.png" alt="appy Logo">
                    </a>
                </div>
                <div class="collapse navbar-collapse pull-right" id="navbar-collapse-1">
                    <ul class="nav navbar-nav nav-pos-right navbar-left nav-split"
                        style=" font-family: 'Open Sans' !important;">
                        <li class="active"><a data-scroll="scrollTo" href="#slider">Home</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#about">About Us</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#feature2">Courses</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#banner">Register</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#product">Production</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#links">Links</a>
                        </li>
                        <li><a data-scroll="scrollTo" href="#gal">Gallery</a>
                        </li>
                        <li><a href="#footer">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <!--/.nav-collapse -->
            </div>
        </nav>

    </header>
    <!-- Slider #1
=== === === === === === === === === === === === === === -->
    <section id="slider" class="section slider">
        <div class="slide--item bg-overlay bg-overlay-dark bg-section"
            style="background-image: url(assets/images/slider/slide-1.jpg);">

            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 text-center wow fadeInUp" data-wow-duration="1s">
                        <div class="slide--headline">Welcome to Musik Factory</div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 text-center wow fadeInUp"
                        data-wow-duration="1s">
                        <div class="slide--bio">“Where words leave off, music begins.”
                        </div>
                    </div>
                </div>
                <!-- .row end -->
            </div>
            <!-- .container end -->
        </div>

    </section>
    <!-- #slider end -->



    <!-- About
=== === === === === === === === === === === === === === -->
    <section id="about" class="section about pb-0">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 wow fadeInUp" style="margin-top: 45px;">
                    <img class="img-responsive center-block" src="assets/images/team/team-4.jpg" alt="ipad" />
                </div>
                <!-- .col-md-6 end -->
                <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-1 text-center ">
                    <div class="heading heading-1 mt-70 wow fadeInUp " data-wow-duration="1s">
                        <h2 class="heading--title  ">Why Us?</h2>
                        <div class="heading--divider"></div>
                    </div>
                    <div class="about-content wow fadeInUp text-justify text-center  " data-wow-duration="1s">
                        <p> Musik Factory is a music school, dedicated to Guruji, Pandit Pandharinath Kolhapure Ji.
                            Musik factory, truly believe that music is the very fabric of our lives and have devoted
                            ourselves to helping students experience the joy of learning music in a fun and engaging
                            way. In depth training in music, breathing exercises and Vocal health is the focus of our
                            music school. We have trained 1000s students in subject of music/performing arts. Our
                            students are successfully performing music gigs, music shows across the globe and are
                            recording on
                            various international platforms like Apple Music, Spotify and many more.
                        </p>
                    </div>
                </div>
                <!-- .col-md-6 end -->
            </div>
            <!-- .row end -->
        </div>
        <!-- .container end -->
    </section>
    <!-- #feature2 end -->
    <!-- Feature #2
=== === === === === === === === === === === === === === -->


    <!-- About
=== === === === === === === === === === === === === === -->
    <section id="about" class="section about pb-0">
        <div class="container ">
            <div class="row ">

                <!-- .col-md-6 end -->
                <div class="col-xs-12 col-sm-12 col-md-6">
                    <div class="heading heading-1 mt-70 wow fadeInUp " data-wow-duration="1s">
                        <h2 class="heading--title  ">Founder Shipra Malhotra</h2>
                        <div class="heading--divider"></div>
                    </div>
                    <div class="about-content wow fadeInUp text-justify " data-wow-duration="1s">
                        <p>Shipra Malhotra, founder of Musik Factory, herself, is a trained Hindustani Classical singer
                            from Gwalior Gharana, Mewati Gharana. Has done her Guitar Aficionado and music production
                            from Berkelee School of Music. She is a Bollywood Singer, lyricist, Composer of various
                            Songs sung for renowned media houses. Some of the songs are “Prabhu Charno main” for Hungama
                            Channel, “In her shoes” for Natrang Theatre, Jammu Kashmir. She is a Singer and composer of
                            the Title track of Bollywood English Golden Jubilee play ''Fourplay” and many more.
                        </p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 wow fadeInUp text-right" style="margin-top: 55px;display: flexbox; justify-content: end;">
                    <img class="img-fluid " src="assets/images/team/team-5.jpg" alt="ipad" />
                </div>
                <!-- .col-md-6 end -->
            </div>
            <!-- .row end -->
        </div>
        <!-- .container end -->
    </section>
    <!-- #feature2 end -->
    <!-- Feature #2
=== === === === === === === === === === === === === === -->

<section>
    
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img src="assets/swiper/swiper1.jpg" alt="image" style="height: 100%; width: 100%;">
                <div class="carousel-caption">
                    <p style="font-size: 18px; color: white;">Deeply honoured and grateful, as our Founder Ms. Shipra Malhotra, shared insights, and passions with an eager audience on the TEDx stage. It is a privilege for our team, unitedly to contribute to the society and add our bit to inspire the change. A word of thanks to the Atlas University for the opportunity.</p>
                    <div class="flat-button-container" style="text-align:center; padding: 5px;">
                        <a class="flat-button blue2 pointer" href="https://youtu.be/Rr9UKAK5Vwk?si=2A0rlXy1TLYyo9c9" target="_blank">Watch Now</a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <img src="assets/swiper/swiper2.jpg" alt="image" style="height: 100%; width: 100%;">
                <div class="carousel-caption">
                    <p style="font-size: 18px; color: white;">Deeply honoured and grateful, as our Founder Ms. Shipra Malhotra, shared insights, and passions with an eager audience on the TEDx stage. It is a privilege for our team, unitedly to contribute to the society and add our bit to inspire the change. A word of thanks to the Atlas University for the opportunity.</p>
                    <div class="flat-button-container" style="text-align:center; padding: 5px;">
                        <a class="flat-button blue2 pointer" href="https://youtu.be/Rr9UKAK5Vwk?si=2A0rlXy1TLYyo9c9" target="_blank">Watch Now</a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <img src="assets/swiper/swiper3.jpg" alt="image" style="height: 100%; width: 100%;">
                <div class="carousel-caption">
                    <p style="font-size: 18px; color: white;">Deeply honoured and grateful, as our Founder Ms. Shipra Malhotra, shared insights, and passions with an eager audience on the TEDx stage. It is a privilege for our team, unitedly to contribute to the society and add our bit to inspire the change. A word of thanks to the Atlas University for the opportunity.</p>
                    <div class="flat-button-container" style="text-align:center; padding: 5px;">
                        <a class="flat-button blue2 pointer" href="https://youtu.be/Rr9UKAK5Vwk?si=2A0rlXy1TLYyo9c9" target="_blank">Watch Now</a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <img src="assets/swiper/swiper4.jpg" alt="image" style="height: 100%; width: 100%;">
                <div class="carousel-caption">
                    <p style="font-size: 18px; color: white;">Deeply honoured and grateful, as our Founder Ms. Shipra Malhotra, shared insights, and passions with an eager audience on the TEDx stage. It is a privilege for our team, unitedly to contribute to the society and add our bit to inspire the change. A word of thanks to the Atlas University for the opportunity.</p>
                    <div class="flat-button-container" style="text-align:center; padding: 5px;">
                        <a class="flat-button blue2 pointer" href="https://youtu.be/Rr9UKAK5Vwk?si=2A0rlXy1TLYyo9c9" target="_blank">Watch Now</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-next" style="color: white;"></div>
        <div class="swiper-button-prev" style="color: white;"></div>
    </div>
</section>


    <section id="feature2" class="flat-row pad-top50px">
        <div class="container">
            <div class="row clearfix">
                <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                    <div class="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
                        <h2 class="heading--title">Our Courses</h2>
                        <p class="heading--desc"></p>
                        <div class="heading--divider mb-80 heading--center"></div>
                    </div>
                </div>

            </div>
            <!-- .row end -->
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12" style="font-family: 'Open Sans' !important;">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course1.jpg" alt="image"></a>
                            <div class="courses-description">
                                <p class="desc text-justify" style="font-family: 'Open Sans' !important;">Hindustani
                                    Classical Music have developed over thousands of
                                    years and are an integral part of Indian culture. You will learn vocal techniques
                                    such as proper breathing and projection, effective practice methods, and the use of
                                    alankars and sargams that enhance your performance. Beginners and Advance are the
                                    two levels through which we complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Hindustani Vocals</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course2.jpg" alt="image"></a>
                            <div class="courses-description">
                                <p class="desc text-justify">Musik Factory help you to learn old & new Popular Hollywood
                                    Hits of different genres and categories, aiming at preparing the students to be able
                                    to perform freely on stage. Beginners and Advance are the two levels through which
                                    we complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Western Vocals</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course3.jpg" alt="image"></a>
                            <div class="courses-description">
                                <p class="desc text-justify">The greatest instrument of all time, the guitar is fairly
                                    portable, relatively inexpensive, easy enough to learn the basics – without any age
                                    limit – and you can take your playing as far as you want. At Musik factory we help
                                    in preparing guitar through dedicated lessons, position playing, Finger Picking
                                    techniques to name a few. Practice Can Not Be Avoided. Beginners and Advance are the
                                    two levels through which we complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Guitar</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course4.jpg" alt="image"></a>
                            <div class="courses-description">
                                <p class="desc text-justify">Electronic Keyboard is the ideal instrument to help develop
                                    one’s musical skills, especially relative pitch and understanding of harmony,
                                    because the keyboard offers a visual support to the comprehension of harmonic
                                    structures. We at Musik factory take in students at very basic levels and then
                                    progress them to more advance concepts in playing keyboard. Beginners and Advance
                                    are the two levels through which we complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Keyboard</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course5.jpg" alt="image"></a>
                            <div class="courses-description">
                                <p class="desc text-justify">One of the major types of veena played in Indian classical
                                    music. As there are not many people teaching Rudra Veena it gives us the opportunity
                                    to teach people this rare instrument. Beginners and Advance are the two levels
                                    through which we complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Rudra Veena</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="flat-courses style1">
                        <div class="courses-thumbnail">
                            <a href="course_single.html"><img src="assets/images/course6.jpg" alt="image"></a>
                            <div class=" courses-description  ">
                                <p class="desc text-justify">The tanpura is a drone instrument of Indian music prevalent
                                    in both the Hindustani and the Carnatic systems. It plays an important role in a
                                    concert of classical music by providing the base note (adharaswara) and by creating
                                    an aesthetic ambience on the stage. A well-tuned tanpura can emit possibly all the
                                    seven notes of the gamut. Beginners and Advance are the two levels through which we
                                    complete the course.</p>
                            </div>
                        </div>
                        <div class="courses-content">
                            <a href="course_single.html">
                                <h6 class="courses-topic">Tanpura</h6>
                            </a>
                        </div>
                    </div><!-- /.flat-courses -->
                </div><!-- /.col-md-3 -->
            </div><!-- /.row -->
        </div><!-- /.container -->

    </section><!-- /.flat-row -->


    <!-- #feature2 end -->
    <!-- Team #1
=== === === === === === === === === === === === === === -->
    <div id="banner" class="section cta pt-0 pb-0">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-7 col-content pl-100">
                    <div class="heading heading-1 wow fadeInUp" data-wow-duration="1s">
                        <h2 class="heading--title">Registration Form</h2>
                        <p class="heading--desc"></p>
                        <div class="heading--divider mb-80 heading--center"></div>
                    </div>
                    <p class="heading--desc"></p>
                    <div class="heading--divider mb-80 heading--center"></div>
                    <div>
                        <div class="row">
                            <form class="mb-0">
                                <div class="form-group">
                                    <div class="col-xs-12 col-sm-12 col-md-6">
                                        <input type="text" class="form-control" id="name" placeholder="Your Name"
                                            required>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-6">
                                        <input type="text" class="form-control" id="add" placeholder="address" required>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-6">
                                        <input type="text" class="form-control" id="no" placeholder="Phone number"
                                            maxlength="10"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" required>
                                    </div>
                                    <!-- .col-md-6 end -->
                                    <div class="col-xs-12 col-sm-12 col-md-6">
                                        <input type="email" class="form-control" id="email" placeholder="Your Email"
                                            required>
                                    </div>
                                    <!-- .col-md-6 end -->
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <select id="courses" class="form-control">
                                            <option>Hindustani Vocals</option>
                                            <option>Western Vocals</option>
                                            <option>Guitar</option>
                                            <option>Keyboard</option>
                                            <option>Rudra Veena</option>
                                            <option>Tanpura</option>
                                        </select>
                                    </div>
                                    <!-- .col-md-12 end -->

                                    <!-- .col-md-12 end -->
                                    <div class="col-xs-12 col-sm-12 col-md-6">
                                        <input type="button" class="btn btn--primary" (click)="registerform()"
                                            value="Register">
                                    </div>
                                    <!-- .col-md-6 end -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 pr-0 pl-0" id="hide-img">
                    <div class="col-img">
                        <div class="bg-section">
                            <img src="assets/images/background/bg-2.jpg" alt="Background" />
                        </div>
                    </div>
                </div>
                <!-- .col-md-6 end-->
            </div>
            <!-- .row end -->
        </div>
        <!-- .container end -->
    </div>
    <!-- #banner2 end -->

    <div id="product" class="bg-white pt-0 pb-0">
        <div class="container">
            <div class="row">
                <!-- <div class="col-xs-12 col-sm-12 col-md-5 pr-0 pl-0">
                    <div class="col-img  bg-overlay">
                        <div class="bg-section">
                            <img src="assets/images/background/bg-1.jpg" alt="Background"/>
                        </div>
                    </div>
                </div> -->
                <!-- .col-md-6 end-->
                <div class="col-xs-12 col-sm-12 col-md-12" style="font-family: 'Open Sans' !important;">
                    <div class="row clearfix">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <div class="heading heading-1 wow fadeInUp" data-wow-duration="1s">
                                <h2 class="heading--title">Music production</h2>
                                <p class="heading--desc text-center">From A to Z, to writing a song for you, creating a melody/rap
                                    for you or may it be recording a raaga or a bhajan for you. We guide you through a
                                    step by step process of, writing the song, giving it a tune, recording it for you
                                    and launch it online for you.</p>
                                <div class="heading--divider mb-80"></div>
                            </div>
                        </div>
                        <!-- .col-md-6 end -->
                    </div>
                    <!-- .row end -->
                    <div class="row mb-50">
                        <!-- Panel #1 -->
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <div class="feature-panel">
                                <div class="feature--icon">
                                    <img class="iconmp" src="assets/images/logo/song writing symbol.png">
                                </div>
                                <div class="feature--content">
                                    <h3>Songwriting</h3>
                                    <p>We specialize in writing lyrics and music such as vocal melodies or instrument
                                        melodies and accompanying background music for you. Having experience and
                                        written songs before for well-known media house gives us the better exposure to
                                        cater your needs.</p>
                                </div>
                            </div>
                            <!-- .feature-panel end -->
                        </div>
                        <!-- .col-md-4 end -->

                        <!-- Panel #2 -->
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <div class="feature-panel">
                                <div class="feature--icon">
                                    <img class="iconmp" src="assets/images/logo/song production.png">
                                </div>
                                <div class="feature--content">
                                    <h3>Song design and production</h3>
                                    <p>In the song design process we help with Filling the arrangement, editing samples
                                        in creative ways, creatively using audio effects, applying automation and
                                        modulation, fixing issues.</p>
                                </div>
                            </div>
                            <!-- .feature-panel end -->
                        </div>
                        <!-- .col-md-4 end -->

                    </div>
                    <!-- .row end -->
                    <div class="row">
                        <!-- Panel #3 -->
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <div class="feature-panel">
                                <div class="feature--icon">
                                    <img class="iconmp" src="assets/images/logo/song mixing 1.png">
                                </div>
                                <div class="feature--content">
                                    <h3>Mixing</h3>
                                    <p>Mixing involves balancing levels, panning sounds, equalizing, compressing,
                                        harmonics enhancing, fixing problems, and adding various effects. Mixing also
                                        involves automation, editing sounds in creative ways, and giving instruments
                                        their own space in the mix. The goal is to sculpt a balanced and unified
                                        arrangement ready for mastering. </p>
                                </div>
                            </div>
                            <!-- .feature-panel end -->
                        </div>
                        <!-- .col-md-4 end -->

                        <!-- Panel #4 -->
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <div class="feature-panel">
                                <div class="feature--icon">
                                    <img class="iconmp" src="assets/images/logo/song masteing 1.png">
                                </div>
                                <div class="feature--content">
                                    <h3>Mastering</h3>
                                    <p>The purpose of mastering is to balance the stereo mix, make all the elements
                                        sound cohesive, and to reach commercial loudness. It also ensures playback
                                        optimization across all speaker systems and media formats.</p>
                                </div>
                            </div>
                            <!-- .feature-panel end -->
                        </div>
                        <!-- .col-md-4 end -->
                    </div>
                    <!-- .row end -->
                </div>
            </div>
            <!-- .row end -->
        </div>
        <!-- .container end -->
    </div>

    <!-- Testimonial #2
=== === === === === === === === === === === === === === -->



    <section id="links">
        <div>
            <div class="row clearfix">
                <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                    <div class="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
                        <h2 class="heading--title">Links</h2>
                        <p class="heading--desc"></p>
                        <div class="heading--divider mb-80 heading--center"></div>
                    </div>
                </div>

            </div>
            <div class="container">

                <div class="customer-logos sliders">
                    <div class="slide"><a class="lightbox" target="_blank">
                            <img src=assets\images\links\img7.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank"
                            href="https://artistaloud.wordpress.com/2018/02/08/in-her-shoes-shipra-malhotra">
                            <img src=assets\images\links\img3.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank" href="https://youtu.be/EL5wtnHU7i4">
                            <img src=assets\images\links\img8.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank"
                            href="http://www.hungama.com/album/prabhu-charno-mein/36748562/">
                            <img src=assets\images\links\img5.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank" href="http://wynk.in/u/ZgrNqZOlt">
                            <img src=assets\images\links\img1.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank"
                            href="https://itunes.apple.com/in/album/prabhu-charno-mein-feat-ankita-joshi-single/1398255583?ls=1&app=itunes.">
                            <img src=assets\images\links\img6.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank"
                            href="https://gaana.com/album/prabhu-charno-mein">
                            <img src=assets\images\links\img4.png>
                        </a></div>
                    <div class="slide"><a class="lightbox" target="_blank"
                            href="https://music.amazon.in/albums/B07DNWMYX5?ref=dm_sh_XOYqnTqPdWujIVSKW6rxjcyEZ">
                            <img src=assets\images\links\img2.png>
                        </a></div>
                </div>
            </div>
        </div>
    </section>

    <div class="container" id="gal">
        <div class="heading heading-1 text--center wow fadeInUp" data-wow-duration="1s">
            <h2 class="heading--title">Gallery</h2>
            <p class="heading--desc"></p>
            <div class="heading--divider mb-80 heading--center"></div>
        </div>
        <div class='row'>
            <div class='col-md-12'>
                <div class="carousel slide media-carousel" id="media">
                    <div class="carousel-inner">
                        <div class="item  active">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider.jpg>
                                            <img src=assets\images\slide\slider.jpg>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider1.jpg>
                                            <img src=assets\images\slide\slider1.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider2.jpg>
                                            <img src=assets\images\slide\slider2.jpg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider3.jpg>
                                            <img src=assets\images\slide\slider3.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider4.jpg>
                                            <img src=assets\images\slide\slider4.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider5.jpg>
                                            <img src=assets\images\slide\slider5.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider6.jpg>
                                            <img src=assets\images\slide\slider6.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider7.jpg>
                                            <img src=assets\images\slide\slider7.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider8.jpg>
                                            <img src=assets\images\slide\slider8.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider9.jpg>
                                            <img src=assets\images\slide\slider9.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider10.jpg>
                                            <img src=assets\images\slide\slider10.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider11.jpg>
                                            <img src=assets\images\slide\slider11.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider12.jpg>
                                            <img src=assets\images\slide\slider12.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider13.jpg>
                                            <img src=assets\images\slide\slider13.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider14.jpg>
                                            <img src=assets\images\slide\slider14.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider15.jpg>
                                            <img src=assets\images\slide\slider15.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider16.jpg>
                                            <img src=assets\images\slide\slider16.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider17.jpg>
                                            <img src=assets\images\slide\slider17.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider18.jpg>
                                            <img src=assets\images\slide\slider18.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider19.jpg>
                                            <img src=assets\images\slide\slider19.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider20.jpg>
                                            <img src=assets\images\slide\slider20.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider22.jpg>
                                            <img src=assets\images\slide\slider22.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider23.jpg>
                                            <img src=assets\images\slide\slider23.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider24.jpg>
                                            <img src=assets\images\slide\slider24.jpg>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider25.jpg>
                                            <img src=assets\images\slide\slider25.jpg>
                                        </a></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="thumbnail"><a class="lightbox" target="_blank"
                                            href=assets\images\slide\slider26.jpg>
                                            <img src=assets\images\slide\slider26.jpg>
                                        </a></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <a data-slide="prev" href="#media" class="left carousel-control">‹</a>
                    <a data-slide="next" href="#media" class="right carousel-control">›</a>
                </div>
            </div>
        </div>
    </div>

    <div id="banner1" class="section cta pt-0 pb-0">
        <div class="container-fluid">
            <div class="row" style="display: flex;justify-content: center;">
                <div class="col-xs-12 col-sm-12 col-md-8 col-content pl-100">
                    <div class="heading heading-1 wow fadeInUp" data-wow-duration="1s">
                        <h2 class="heading--title">Comments & Reviews</h2>
                        <p class="heading--desc"></p>
                        <div class="heading--divider mb-80 heading--center"></div>
                    </div>
                    <p class="heading--desc"></p>
                    <div class="heading--divider mb-80 heading--center"></div>
                    <div>
                        <div class="row">

                            <form class="mb-0" [formGroup]="addFrom">
                                <div class="form-group">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <input type="text" class="form-control" formControlName="Name" id="rname"
                                            placeholder="Your Name" required>
                                        <div
                                            *ngIf="addFrom.get('Name').invalid && (addFrom.get('Name').dirty || addFrom.get('Name').touched)">
                                            <div *ngIf="addFrom.get('Name').errors.required">
                                                <p class="alerts">Please Enter Your Name</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <textarea class="form-control" tabindex="3" placeholder="Add a review"
                                            name="message" formControlName="Message" id="message-contact"
                                            required></textarea>
                                        <div *ngIf="addFrom.get('Message').touched ||  (addFrom.get('Message').dirty)">
                                            <p class="text-right"><span id="count"></span><span>/300</span></p>
                                            <div
                                                *ngIf="addFrom.get('Message').invalid && (addFrom.get('Message').dirty)">

                                                <div *ngIf="addFrom.get('Message').errors.maxlength">
                                                    <p class="alert" style="margin: 0px;padding: 0px;">Please enter Less
                                                        then 300 charater</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- .col-md-12 end -->
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <div class="row text-center text-md-left">
                                            <div class="col-xs-12 col-sm-12 col-md-6" style="padding-top: 10px;"> 
                                                <div id="stars" class="starrr"
                                                    style="font-size: xx-large; padding-right: 10px;"></div>
                                                You gave a rating of <span id="count">0</span> star(s)
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-6 text-center text-md-right" style="padding-top: 10px;">
                                                <input type="button" class="btn btn--primary" (click)="reviewform()"
                                                    value="Comment" [disabled]="!addFrom.valid"></div>
                                        </div>

                                    </div>
                                    <!-- .col-md-6 end -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- .col-md-6 end-->
            </div>
            <!-- .row end -->
        </div>
        <!-- .container end -->
    </div>
    <!-- #banner2 end -->

    <div class="row" style="display: flex;justify-content: center; padding-bottom: 30px;  margin: 0;">
        <div class="col-xs-12 col-sm-12 col-md-12">
            <div id="respond1" class="comment-respond contact style2" *ngIf="reviewList">
                <div class="swiper-container4" style="overflow-x: hidden;">
                    <div class="swiper-wrapper d-flex align-items-center">
                        <div class="swiper-slide" *ngFor="let i of reviewList"
                            style="margin-right: 60px;margin-left: 4px;">
                            <div class="card" style="height:100%;">
                                <div class="card-body ">
                                    <h4 style="color: #ffaa30;"><b>{{i.name}}</b></h4>
                                    <p class="card-text">{{i.review}}</p>
                                    <div>{{i.star}}/5
                                        <span id="star1" class="fa fa-star checked"></span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>

                </div>
            </div>
        </div>
    </div>

    <!-- Footer #5
============================================= -->
    <footer id="footer" class="footer">
        <!-- Widget Section
============================================= -->
        <div class="footer-widget">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-4 footer--widget-about">
                        <div class="footer--widget-content">
                            <img class="mb-20" src="assets/images/logo/logo-dark.png" alt="logo">
                            <br>
                            <div class="col-12 footer--widget-contact">
                                <div class="footer--widget-content">
                                    <ul class="list-unstyled">
                                        <li class="p-0 m-0"><i class="fa fa-phone "></i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 99206 79542
                                        </li>
                                        <li class="p-0 m-0"><i
                                                class="fa fa-envelope-o mb-5"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shipra212@gmail.com
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="social">
                                <a class="share-twitter" href="#"><i class="fa fa-twitter"></i></a>
                                <a class="share-facebook" href="#"><i class="fa fa-facebook"></i></a>
                                <a class="share-linkedin" href="#"><i class="fa fa-linkedin"></i></a>
                                <a class="share-pinterest" href="#"><i class="fa fa-pinterest-p"></i></a>
                            </div>
                        </div>
                    </div><!-- .col-md-4 end -->
                    <div class="col-xs-12 col-sm-6 col-md-4 footer--widget-about" id="hide">
                        <div class="col-xs-12 col-sm-6 col-md-6 footer--widget-contact">
                            <div class="footer--widget-title ">
                                <h5> Links</h5>
                            </div>
                            <div class="footer--widget-content">
                                <ul class="list-unstyled  ">
                                    <li class="active P-0 m-0"><a class="m-0" data-scroll="scrollTo"
                                            href="#slider">Home</a>
                                    </li>
                                    <li class="p-0 m-0"><a class="m-0" data-scroll="scrollTo" href="#about">About Us</a>
                                    </li>
                                    <li class="p-0 m-0"><a class="m-0" data-scroll="scrollTo"
                                            href="#feature2">Courses</a>
                                    </li>
                                    <li class="p-0 m-0"><a class="m-0" data-scroll="scrollTo"
                                            href="#banner">Register</a>
                                    </li>
                                    <li class="p-0 m-0"><a class="m-0" data-scroll="scrollTo"
                                            href="#testimonial">Gallery</a>
                                    </li>
                                    <li class="p-0 m-0"><a class="m-0" href="#footer">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><!-- .col-md-4 end -->

                    <div class="col-xs-12 col-sm-6 col-md-4 footer--widget-contact">
                        <div class="footer--widget-title">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="footer--widget-content">
                            <form class="mb-0">
                                <div class="col-xs-12 col-sm-12 col-md-6">
                                    <input type="text" id="name1" class="form-control" name="your-name"
                                        placeholder="Your Name" required>
                                </div>
                                <!-- .col-md-6 end -->
                                <div class="col-xs-12 col-sm-12 col-md-6">
                                    <input type="email" id="email1" class="form-control" name="your-email"
                                        placeholder="Your Email" required>
                                </div>
                                <!-- .col-md-6 end -->
                                <!-- .col-md-12 end -->
                                <div class="col-xs-12 col-sm-12 col-md-12">
                                    <textarea class="form-control" id="msg1" name="your-message" rows="2"
                                        placeholder="Your Message" required></textarea>
                                </div>
                                <!-- .col-md-12 end -->
                                <div class="col-xs-12 col-sm-12 col-md-6">
                                    <!-- <button  class="btn btn--primary"  (click)="contactform()">send request</button> -->
                                    <input type="button" class="btn btn--primary" (click)="contactform()"
                                        value="Send Request">
                                </div>
                                <!-- .col-md-6 end -->
                            </form>
                        </div>
                    </div>
                    <!-- .col-md-4 end -->
                    <!-- .col-md-4 end -->
                </div>
            </div><!-- .container end -->
        </div><!-- .footer-widget end -->
        <!-- Copyrights
============================================= -->
        <div class="footer--copyright">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 text--center">
                        <a data-scroll="scrollTo" href="https://www.kenmarkitan.com/home">Copyright © 2020 | Powered By
                            Kenmark ITan Solutions | All Rights Reserved.</a>
                    </div>
                </div>
            </div>
            <!-- .container end -->
        </div>
    </footer>
</div>


<!-- #wrapper end -->