import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private domain:string="https://api.musikfactory.in/api/";
  constructor(public http:HttpClient) { }

  usersignUp(data){
return this.http.post(this.domain+"registration",data);
  }
  usercontactUs(data){
    return this.http.post(this.domain+"contactUs",data);
      }
  userreview(data){
    return this.http.post(this.domain+"review",data);
      }
      getreview() {
        return this.http.get(this.domain + "review");
      }
}
